import React from "react"

export default function ChipNotificationHeroComponent() {
  return (
    <div className="flex items-center justify-center">
      {/*<!-- Component: Fully rounded elevated chip notification with grouped avatars --> */}
      <div className="inline-flex items-center gap-4 rounded-full border border-slate-200 bg-white/50 p-0.5 pr-4 shadow shadow-slate-700/10  ">
        <span className="flex">
          <a
            href="#"
            className="relative -mx-2 inline-flex h-10 w-10 items-center justify-center rounded-full text-white first:ml-0"
          >
            <img
              src="https://i.pravatar.cc/40?img=8"
              alt="user name"
              title="user name"
              width="40"
              height="40"
              className="max-w-full rounded-full"
            />
          </a>
          <a
            href="#"
            className="relative -mx-2 inline-flex h-10 w-10 items-center justify-center rounded-full text-white first:ml-0"
          >
            <img
              src="https://i.pravatar.cc/40?img=10"
              alt="user name"
              title="user name"
              width="40"
              height="40"
              className="max-w-full rounded-full"
            />
          </a>
          <a
            href="#"
            className="relative -mx-2 inline-flex h-10 w-10 items-center justify-center rounded-full text-white first:ml-0"
          >
            <img
              src="https://i.pravatar.cc/40?img=11"
              alt="user name"
              title="user name"
              width="40"
              height="40"
              className="max-w-full rounded-full"
            />
          </a>
        </span>
        <div className="flex items-center gap-1 text-slate-500 ">
          {/*    <!-- Text displayed in big screens --> */}
          <span className="font-semibold"> 30k+ </span>
          {/*    <!-- Text displayed in big screens --> */}
          <span>satisfied users</span>
        </div>
      </div>
      {/*<!-- End Fully rounded elevated chip notification with grouped avatars --> */}
    </div>
  )
}
