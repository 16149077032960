import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function ChipNotificationAllRoundedTrailButtonPreview(props) {
  const {
    ChipNotificationRoundedTrailButton,
    ChipNotificationRoundedTrailButtonLeadLink,
    ChipNotificationRoundedTrailButtonElevated,
    ChipNotificationRoundedTrailButtonLinkElevated,
  } = props

  return (
    <>
      <div className="flex max-w-3xl flex-col items-center justify-center gap-10">
        <div>
          <CopyComponent
            copyToClipboardCode={ChipNotificationRoundedTrailButton}
            componentName="ChipNotificationRoundedTrailButton"
          >
            {/*<!-- Component: Chip notification with trailing button --> */}
            <div className="inline-flex items-center gap-4 rounded border border-slate-200 bg-white/50 p-0.5 pl-4">
              <div className="flex items-center gap-1 text-slate-500">
                {/* Text displayed in big screens */}
                <span className="hidden md:block">
                  Version 3.40, with 20 new components!{" "}
                </span>
                {/* Text displayed in sm screens */}
                <span className="block md:hidden">Version 3.40</span>
              </div>
              <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                <span>See whats new</span>
              </button>
            </div>
            {/*<!-- End Chip notification with trailing button --> */}
          </CopyComponent>
        </div>

        <div>
          <CopyComponent
            copyToClipboardCode={ChipNotificationRoundedTrailButtonElevated}
            componentName="ChipNotificationRoundedTrailButtonElevated"
          >
            {/*<!-- Component: Elevated chip notification with trailing button --> */}
            <div className="inline-flex items-center gap-4 rounded border border-slate-200 bg-white/50 p-0.5 pl-4 shadow-sm shadow-slate-700/10">
              <div className="flex items-center gap-1 text-slate-500">
                {/* Text displayed in big screens */}
                <span className="hidden md:block">
                  Version 3.40, with 20 new components!{" "}
                </span>
                {/* Text displayed in sm screens */}
                <span className="block md:hidden">Version 3.40</span>
              </div>
              <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                <span>See whats new</span>
              </button>
            </div>
            {/*<!-- End Elevated chip notification with trailing button --> */}
          </CopyComponent>
        </div>

        <div>
          <CopyComponent
            copyToClipboardCode={ChipNotificationRoundedTrailButtonLeadLink}
            componentName="ChipNotificationRoundedTrailButtonLeadLink"
          >
            {/*<!-- Component: Chip notification with trailing button and leading link --> */}
            <div className="inline-flex items-center gap-4 rounded border border-slate-200 bg-white/50 p-0.5 pl-4">
              <div className="flex items-center gap-1 text-slate-500">
                <a
                  href="javascript:void(0)"
                  className="inline-flex items-center justify-center gap-2 justify-self-center whitespace-nowrap tracking-wide text-emerald-500 transition duration-300 hover:text-emerald-600 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none"
                >
                  <span>Check what's new</span>
                </a>
                <span className="hidden md:block">with 20 new components!</span>
              </div>
              <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                <span>version 3.40</span>
              </button>
            </div>
            {/*<!-- End Chip notification with trailing button and leading link --> */}
          </CopyComponent>
        </div>

        <div>
          <CopyComponent
            copyToClipboardCode={ChipNotificationRoundedTrailButtonLinkElevated}
            componentName="ChipNotificationRoundedTrailButtonLinkElevated"
          >
            {/*<!-- Component: Elevated chip notification with trailing button and leading link --> */}
            <div className="inline-flex items-center gap-4 rounded border border-slate-200 bg-white/50 p-0.5 pl-4 shadow-sm shadow-slate-700/10">
              <div className="flex items-center gap-1 text-slate-500">
                <a
                  href="javascript:void(0)"
                  className="inline-flex items-center justify-center gap-2 justify-self-center whitespace-nowrap tracking-wide text-emerald-500 transition duration-300 hover:text-emerald-600 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none"
                >
                  <span>Check what's new</span>
                </a>
                <span className="hidden md:block">with 20 new components!</span>
              </div>
              <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
                <span>version 3.40</span>
              </button>
            </div>
            {/*<!-- End Elevated chip notification with trailing button and leading link --> */}
          </CopyComponent>
        </div>
      </div>
    </>
  )
}
