import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function ChipNotificationRoundedLeadIconPreview(props) {
  const {
    ChipNotificationRoundedLeadIcon,
    ChipNotificationRoundedLeadIconElevated,
    ChipNotificationRoundedLeadIconDark,
  } = props

  return (
    <div className="flex max-w-3xl flex-col items-center justify-center gap-10">
      <div>
        <CopyComponent
          copyToClipboardCode={ChipNotificationRoundedLeadIcon}
          componentName="ChipNotificationRoundedLeadIcon"
        >
          {/*<!-- Component: Chip notification with leading icon --> */}
          <div className="inline-flex items-center gap-4 rounded border border-slate-200 bg-white/50 p-0.5 pr-4 ">
            <span className="inline-flex h-10 w-10 items-center justify-center rounded bg-emerald-500 text-white transition duration-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.5 18.75h-9m9 0a3 3 0 0 1 3 3h-15a3 3 0 0 1 3-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 0 1-.982-3.172M9.497 14.25a7.454 7.454 0 0 0 .981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 0 0 7.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 0 0 2.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 0 1 2.916.52 6.003 6.003 0 0 1-5.395 4.972m0 0a6.726 6.726 0 0 1-2.749 1.35m0 0a6.772 6.772 0 0 1-3.044 0"
                />
              </svg>
            </span>
            <div className="flex items-center gap-1 text-slate-500 ">
              <span className="flex gap-1">
                {" "}
                #1{" "}
                <span className="hidden md:block">
                  {" "}
                  product of the year{" "}
                </span>{" "}
                in
              </span>
              <a
                href="javascript:void(0)"
                className="inline-flex items-center justify-center gap-2 justify-self-center whitespace-nowrap tracking-wide text-emerald-500 transition duration-300 hover:text-emerald-600 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none"
              >
                <span>Product Hunt</span>
              </a>
            </div>
          </div>
          {/*<!-- End Chip notification with leading icon --> */}
        </CopyComponent>
      </div>
      <div>
        <CopyComponent
          copyToClipboardCode={ChipNotificationRoundedLeadIconElevated}
          componentName="ChipNotificationRoundedLeadIconElevated"
        >
          {/*<!-- Component: Elevated chip notification with leading icon --> */}
          <div className="inline-flex items-center gap-4 rounded border border-slate-200 bg-white/50 p-0.5 pr-4 shadow shadow-slate-700/10  ">
            <span className="inline-flex h-10 w-10 items-center justify-center rounded bg-emerald-500 text-white transition duration-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.5 18.75h-9m9 0a3 3 0 0 1 3 3h-15a3 3 0 0 1 3-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 0 1-.982-3.172M9.497 14.25a7.454 7.454 0 0 0 .981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 0 0 7.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 0 0 2.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 0 1 2.916.52 6.003 6.003 0 0 1-5.395 4.972m0 0a6.726 6.726 0 0 1-2.749 1.35m0 0a6.772 6.772 0 0 1-3.044 0"
                />
              </svg>
            </span>
            <div className="flex items-center gap-1 text-slate-500 ">
              <span className="flex gap-1">
                {" "}
                #1{" "}
                <span className="hidden md:block">
                  {" "}
                  product of the year{" "}
                </span>{" "}
                in
              </span>
              <a
                href="javascript:void(0)"
                className="inline-flex items-center justify-center gap-2 justify-self-center whitespace-nowrap tracking-wide text-emerald-500 transition duration-300 hover:text-emerald-600 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none"
              >
                <span>Product Hunt</span>
              </a>
            </div>
          </div>
          {/*<!-- End Elevated chip notification with leading icon --> */}
        </CopyComponent>
      </div>
      <div>
        <CopyComponent
          copyToClipboardCode={ChipNotificationRoundedLeadIconDark}
          componentName="ChipNotificationRoundedLeadIconDark"
        >
          {/*<!-- Component: Dark chip notification with leading icon --> */}
          <div className="inline-flex items-center gap-4 rounded border border-slate-800 bg-slate-900 p-0.5 pr-4">
            <span className="inline-flex h-10 w-10 items-center justify-center rounded bg-slate-700 text-slate-100 transition duration-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.5 18.75h-9m9 0a3 3 0 0 1 3 3h-15a3 3 0 0 1 3-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 0 1-.982-3.172M9.497 14.25a7.454 7.454 0 0 0 .981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 0 0 7.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 0 0 2.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 0 1 2.916.52 6.003 6.003 0 0 1-5.395 4.972m0 0a6.726 6.726 0 0 1-2.749 1.35m0 0a6.772 6.772 0 0 1-3.044 0"
                />
              </svg>
            </span>
            <div className="flex items-center gap-1 text-slate-200">
              <span className="flex gap-1">
                {" "}
                #1{" "}
                <span className="hidden md:block">
                  {" "}
                  product of the year{" "}
                </span>{" "}
                in
              </span>
              <a
                href="javascript:void(0)"
                className="inline-flex items-center justify-center gap-2 justify-self-center whitespace-nowrap tracking-wide text-emerald-500 transition duration-300 hover:text-emerald-600 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none"
              >
                <span>Product Hunt</span>
              </a>
            </div>
          </div>
          {/*<!-- End Dark chip notification with leading icon --> */}
        </CopyComponent>
      </div>
    </div>
  )
}
