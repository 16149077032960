import React, { useState } from "react"
import { Link } from "gatsby"

// Components
import Seo from "../../../components/seo"
import SidebarLayout from "../../../layouts/SidebarLayout"
import Section from "../../../components/section/Section"
import ogImage from "../../../images/seo/chip-notifications/chip-notification.png"
import ContentNavigation from "../../../components/scrollspy"

// Libraries / Components for Preview
import PreviewBlock from "../../../components/preview"

// Preview
import ChipNotificationAllRoundedLeadButtonPreview from "../../../library/components/chip-notification/react/_preview/lead_button"
import ChipNotificationAllRoundedTrailButtonPreview from "../../../library/components/chip-notification/react/_preview/trail_button"
import ChipNotificationAllRoundedFullLeadButtonPreview from "../../../library/components/chip-notification/react/_preview/lead_button-rounded"
import ChipNotificationAllRoundedFullTrailButtonPreview from "../../../library/components/chip-notification/react/_preview/trail_button-rounded"
import ChipNotificationAllRoundedLeadIconPreview from "../../../library/components/chip-notification/react/_preview/lead_icon"
import ChipNotificationAllRoundedFullLeadIconPreview from "../../../library/components/chip-notification/react/_preview/lead_icon-rounded"
import ChipNotificationRoundedFullAvatarsPreview from "../../../library/components/chip-notification/react/_preview/avatars-rounded"
import ChipNotificationAllRoundedTrailIconPreview from "../../../library/components/chip-notification/react/_preview/trail_icon"
import ChipNotificationAllRoundedFullTrailIconPreview from "../../../library/components/chip-notification/react/_preview/trail_icon-rounded"

import ChipNotificationHeroComponent from "../../../library/components/chip-notification/react/_preview/ChipNotificationHeroComponent"

// Rounded leading button
const ChipNotificationRoundedLeadButtonJsx = require("!!raw-loader!../../../library/components/chip-notification/react/rounded/lead_button.jsx")
const ChipNotificationRoundedLeadButtonHTML = require("!!raw-loader!../../../library/components/chip-notification/html/rounded/lead_button.html")

const ChipNotificationRoundedLeadButtonTrailLinkJsx = require("!!raw-loader!../../../library/components/chip-notification/react/rounded/lead_button-trail_link.jsx")
const ChipNotificationRoundedLeadButtonTrailLinkHTML = require("!!raw-loader!../../../library/components/chip-notification/html/rounded/lead_button-trail_link.html")

const ChipNotificationRoundedLeadButtonElevatedJsx = require("!!raw-loader!../../../library/components/chip-notification/react/rounded/lead_button-elevated.jsx")
const ChipNotificationRoundedLeadButtonElevatedHTML = require("!!raw-loader!../../../library/components/chip-notification/html/rounded/lead_button-elevated.html")

const ChipNotificationRoundedLeadButtonTrailLinkElevatedJsx = require("!!raw-loader!../../../library/components/chip-notification/react/rounded/lead_button-trail_link-elevated.jsx")
const ChipNotificationRoundedLeadButtonTrailLinkElevatedHTML = require("!!raw-loader!../../../library/components/chip-notification/html/rounded/lead_button-trail_link-elevated.html")

// Rounded trailing button
const ChipNotificationRoundedTrailButtonJsx = require("!!raw-loader!../../../library/components/chip-notification/react/rounded/trail_button.jsx")
const ChipNotificationRoundedTrailButtonHTML = require("!!raw-loader!../../../library/components/chip-notification/html/rounded/trail_button.html")

const ChipNotificationRoundedTrailButtonLeadLinkJsx = require("!!raw-loader!../../../library/components/chip-notification/react/rounded/trail_button-lead_link.jsx")
const ChipNotificationRoundedTrailButtonLeadLinkHTML = require("!!raw-loader!../../../library/components/chip-notification/html/rounded/trail_button-lead_link.html")

const ChipNotificationRoundedTrailButtonElevatedJsx = require("!!raw-loader!../../../library/components/chip-notification/react/rounded/trail_button-elevated.jsx")
const ChipNotificationRoundedTrailButtonElevatedHTML = require("!!raw-loader!../../../library/components/chip-notification/html/rounded/trail_button-elevated.html")

const ChipNotificationRoundedTrailButtonLeadLinkElevatedJsx = require("!!raw-loader!../../../library/components/chip-notification/react/rounded/trail_button-lead_link-elevated.jsx")
const ChipNotificationRoundedTrailButtonLeadLinkElevatedHTML = require("!!raw-loader!../../../library/components/chip-notification/html/rounded/trail_button-lead_link-elevated.html")

// Rounded full leading button
const ChipNotificationRoundedFullLeadButtonJsx = require("!!raw-loader!../../../library/components/chip-notification/react/rounded-full/lead_button.jsx")
const ChipNotificationRoundedFullLeadButtonHTML = require("!!raw-loader!../../../library/components/chip-notification/html/rounded-full/lead_button.html")

const ChipNotificationRoundedFullLeadButtonTrailLinkJsx = require("!!raw-loader!../../../library/components/chip-notification/react/rounded-full/lead_button-trail_link.jsx")
const ChipNotificationRoundedFullLeadButtonTrailLinkHTML = require("!!raw-loader!../../../library/components/chip-notification/html/rounded-full/lead_button-trail_link.html")

const ChipNotificationRoundedFullLeadButtonElevatedJsx = require("!!raw-loader!../../../library/components/chip-notification/react/rounded-full/lead_button-elevated.jsx")
const ChipNotificationRoundedFullLeadButtonElevatedHTML = require("!!raw-loader!../../../library/components/chip-notification/html/rounded-full/lead_button-elevated.html")

const ChipNotificationRoundedFullLeadButtonTrailLinkElevatedJsx = require("!!raw-loader!../../../library/components/chip-notification/react/rounded-full/lead_button-trail_link-elevated.jsx")
const ChipNotificationRoundedFullLeadButtonTrailLinkElevatedHTML = require("!!raw-loader!../../../library/components/chip-notification/html/rounded-full/lead_button-trail_link-elevated.html")

// Rounded full trailing button
const ChipNotificationRoundedFullTrailButtonJsx = require("!!raw-loader!../../../library/components/chip-notification/react/rounded-full/trail_button.jsx")
const ChipNotificationRoundedFullTrailButtonHTML = require("!!raw-loader!../../../library/components/chip-notification/html/rounded-full/trail_button.html")

const ChipNotificationRoundedFullTrailButtonLeadLinkJsx = require("!!raw-loader!../../../library/components/chip-notification/react/rounded-full/trail_button-lead_link.jsx")
const ChipNotificationRoundedFullTrailButtonLeadLinkHTML = require("!!raw-loader!../../../library/components/chip-notification/html/rounded-full/trail_button-lead_link.html")

const ChipNotificationRoundedFullTrailButtonElevatedJsx = require("!!raw-loader!../../../library/components/chip-notification/react/rounded-full/trail_button-elevated.jsx")
const ChipNotificationRoundedFullTrailButtonElevatedHTML = require("!!raw-loader!../../../library/components/chip-notification/html/rounded-full/trail_button-elevated.html")

const ChipNotificationRoundedFullTrailButtonLeadLinkElevatedJsx = require("!!raw-loader!../../../library/components/chip-notification/react/rounded-full/trail_button-lead_link-elevated.jsx")
const ChipNotificationRoundedFullTrailButtonLeadLinkElevatedHTML = require("!!raw-loader!../../../library/components/chip-notification/html/rounded-full/trail_button-lead_link-elevated.html")

// Rounded full grouped avatars
const ChipNotificationRoundedFullGroupedAvatarsJsx = require("!!raw-loader!../../../library/components/chip-notification/react/rounded-full/grouped_avatars.jsx")
const ChipNotificationRoundedFullGroupedAvatarsHTML = require("!!raw-loader!../../../library/components/chip-notification/html/rounded-full/grouped_avatars.html")

const ChipNotificationRoundedFullGroupedAvatarsElevatedJsx = require("!!raw-loader!../../../library/components/chip-notification/react/rounded-full/grouped_avatars-elevated.jsx")
const ChipNotificationRoundedFullGroupedAvatarsElevatedHTML = require("!!raw-loader!../../../library/components/chip-notification/html/rounded-full/grouped_avatars-elevated.html")

// Rounded leading button
const ChipNotificationRoundedLeadIconJsx = require("!!raw-loader!../../../library/components/chip-notification/react/rounded/lead_icon.jsx")
const ChipNotificationRoundedLeadIconHTML = require("!!raw-loader!../../../library/components/chip-notification/html/rounded/lead_icon.html")

const ChipNotificationRoundedLeadIconElevatedJsx = require("!!raw-loader!../../../library/components/chip-notification/react/rounded/lead_icon-elevated.jsx")
const ChipNotificationRoundedLeadIconElevatedHTML = require("!!raw-loader!../../../library/components/chip-notification/html/rounded/lead_icon-elevated.html")

const ChipNotificationRoundedLeadIconDarkJsx = require("!!raw-loader!../../../library/components/chip-notification/react/rounded/lead_icon-dark.jsx")
const ChipNotificationRoundedLeadIconDarkHTML = require("!!raw-loader!../../../library/components/chip-notification/html/rounded/lead_icon-dark.html")

// Rounded full leading button
const ChipNotificationRoundedFullLeadIconJsx = require("!!raw-loader!../../../library/components/chip-notification/react/rounded-full/lead_icon.jsx")
const ChipNotificationRoundedFullLeadIconHTML = require("!!raw-loader!../../../library/components/chip-notification/html/rounded-full/lead_icon.html")

const ChipNotificationRoundedFullLeadIconElevatedJsx = require("!!raw-loader!../../../library/components/chip-notification/react/rounded-full/lead_icon-elevated.jsx")
const ChipNotificationRoundedFullLeadIconElevatedHTML = require("!!raw-loader!../../../library/components/chip-notification/html/rounded-full/lead_icon-elevated.html")

const ChipNotificationRoundedFullLeadIconDarkJsx = require("!!raw-loader!../../../library/components/chip-notification/react/rounded-full/lead_icon-dark.jsx")
const ChipNotificationRoundedFullLeadIconDarkHTML = require("!!raw-loader!../../../library/components/chip-notification/html/rounded-full/lead_icon-dark.html")

// Rounded trailing button
const ChipNotificationRoundedTrailIconJsx = require("!!raw-loader!../../../library/components/chip-notification/react/rounded/trail_icon.jsx")
const ChipNotificationRoundedTrailIconHTML = require("!!raw-loader!../../../library/components/chip-notification/html/rounded/trail_icon.html")

const ChipNotificationRoundedTrailIconElevatedJsx = require("!!raw-loader!../../../library/components/chip-notification/react/rounded/trail_icon-elevated.jsx")
const ChipNotificationRoundedTrailIconElevatedHTML = require("!!raw-loader!../../../library/components/chip-notification/html/rounded/trail_icon-elevated.html")

const ChipNotificationRoundedTrailIconDarkJsx = require("!!raw-loader!../../../library/components/chip-notification/react/rounded/trail_icon-dark.jsx")
const ChipNotificationRoundedTrailIconDarkHTML = require("!!raw-loader!../../../library/components/chip-notification/html/rounded/trail_icon-dark.html")

// Rounded full trailing button
const ChipNotificationRoundedFullTrailIconJsx = require("!!raw-loader!../../../library/components/chip-notification/react/rounded-full/trail_icon.jsx")
const ChipNotificationRoundedFullTrailIconHTML = require("!!raw-loader!../../../library/components/chip-notification/html/rounded-full/trail_icon.html")

const ChipNotificationRoundedFullTrailIconElevatedJsx = require("!!raw-loader!../../../library/components/chip-notification/react/rounded-full/trail_icon-elevated.jsx")
const ChipNotificationRoundedFullTrailIconElevatedHTML = require("!!raw-loader!../../../library/components/chip-notification/html/rounded-full/trail_icon-elevated.html")

const ChipNotificationRoundedFullTrailIconDarkJsx = require("!!raw-loader!../../../library/components/chip-notification/react/rounded-full/trail_icon-dark.jsx")
const ChipNotificationRoundedFullTrailIconDarkHTML = require("!!raw-loader!../../../library/components/chip-notification/html/rounded-full/trail_icon-dark.html")

export default function ChipNotificationPage() {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "LeadingButton",
      title: "Leading button chip notifications",
      active_tab: 1,
    },
    {
      component_name: "TrailingButton",
      title: "Trailing button",
      active_tab: 1,
    },
    {
      component_name: "LeadIcon",
      title: "Leading icon",
      active_tab: 1,
    },
    {
      component_name: "TrailIcon",
      title: "Trailing icon",
      active_tab: 1,
    },
    {
      component_name: "RoundedFullLeadingButton",
      title: "Rounded full leading button",
      active_tab: 1,
    },
    {
      component_name: "RoundedFullTrailingButton",
      title: "Rounded full trailing button",
      active_tab: 1,
    },
    {
      component_name: "RoundedFullGroupedAvatars",
      title: "Rounded full grouped avatars",
      active_tab: 1,
    },
    {
      component_name: "RoundedFullLeadIcon",
      title: "Rounded full leading icon",
      active_tab: 1,
    },
    {
      component_name: "RoundedFullTrailIcon",
      title: "Rounded full trailing icon",
      active_tab: 1,
    },
  ])

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Chip Notifications - WindUI Component Library"
        ogtitle="Tailwind CSS Chip Notifications - WindUI Component Library"
        description="Chip Notification components for Tailwind CSS, are compact elements that allow to highlight meaningful information, or direct users to important content. Built with Tailwind CSS by WindUI."
        ogdescription="Chip Notification components for Tailwind CSS, are compact elements that allow to highlight meaningful information, or direct users to important content. Built with Tailwind CSS by WindUI."
        url="components/chip-notifications/"
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Chip Notifications, Chip Notifications, Chip Notification components, WindUI, windui, wind ui"
        ogimage={ogImage}
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}

        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Chip Notifications</h1>
          <p>
            Compact elements that allow to highlight meaningful information, or
            direct users to important content.
          </p>

          {/* Hero Preview Section */}
          <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 sm:p-16 [&>*]:w-full">
            <section
              className="not-prose max-w-full"
              aria-multiselectable="false"
            >
              <ChipNotificationHeroComponent />
            </section>
          </div>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          <h3 id="LeadingButton">Chip notifications with leading button</h3>

          <PreviewBlock
            id="LeadingButton"
            HtmlComponent={ChipNotificationRoundedLeadButtonHTML.default}
            JsxComponent={ChipNotificationRoundedLeadButtonJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <ChipNotificationAllRoundedLeadButtonPreview
                ChipNotificationRoundedLeadButton={
                  activeTabs[0].active_tab === 1
                    ? ChipNotificationRoundedLeadButtonHTML.default
                    : ChipNotificationRoundedLeadButtonJsx.default
                }
                ChipNotificationRoundedLeadButtonTrailLink={
                  activeTabs[0].active_tab === 1
                    ? ChipNotificationRoundedLeadButtonTrailLinkHTML.default
                    : ChipNotificationRoundedLeadButtonTrailLinkJsx.default
                }
                ChipNotificationRoundedLeadButtonElevated={
                  activeTabs[0].active_tab === 1
                    ? ChipNotificationRoundedLeadButtonElevatedHTML.default
                    : ChipNotificationRoundedLeadButtonElevatedJsx.default
                }
                ChipNotificationRoundedLeadButtonTrailLinkElevated={
                  activeTabs[0].active_tab === 1
                    ? ChipNotificationRoundedLeadButtonTrailLinkElevatedHTML.default
                    : ChipNotificationRoundedLeadButtonTrailLinkElevatedJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="TrailingButton">Chip notifications with trailing button</h3>

          <PreviewBlock
            id="TrailingButton"
            HtmlComponent={ChipNotificationRoundedTrailButtonHTML.default}
            JsxComponent={ChipNotificationRoundedTrailButtonJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <ChipNotificationAllRoundedTrailButtonPreview
                ChipNotificationRoundedTrailButton={
                  activeTabs[1].active_tab === 1
                    ? ChipNotificationRoundedTrailButtonHTML.default
                    : ChipNotificationRoundedTrailButtonJsx.default
                }
                ChipNotificationRoundedTrailButtonLeadLink={
                  activeTabs[1].active_tab === 1
                    ? ChipNotificationRoundedTrailButtonLeadLinkHTML.default
                    : ChipNotificationRoundedTrailButtonLeadLinkJsx.default
                }
                ChipNotificationRoundedTrailButtonElevated={
                  activeTabs[1].active_tab === 1
                    ? ChipNotificationRoundedTrailButtonElevatedHTML.default
                    : ChipNotificationRoundedTrailButtonElevatedJsx.default
                }
                ChipNotificationRoundedTrailButtonLinkElevated={
                  activeTabs[1].active_tab === 1
                    ? ChipNotificationRoundedTrailButtonLeadLinkElevatedHTML.default
                    : ChipNotificationRoundedTrailButtonLeadLinkElevatedJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="LeadIcon">Chip notifications with leading icon</h3>

          <PreviewBlock
            id="LeadIcon"
            HtmlComponent={ChipNotificationRoundedLeadIconHTML.default}
            JsxComponent={ChipNotificationRoundedLeadIconJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <ChipNotificationAllRoundedLeadIconPreview
                ChipNotificationRoundedLeadIcon={
                  activeTabs[2].active_tab === 1
                    ? ChipNotificationRoundedLeadIconHTML.default
                    : ChipNotificationRoundedLeadIconJsx.default
                }
                ChipNotificationRoundedLeadIconElevated={
                  activeTabs[2].active_tab === 1
                    ? ChipNotificationRoundedLeadIconElevatedHTML.default
                    : ChipNotificationRoundedLeadIconElevatedJsx.default
                }
                ChipNotificationRoundedLeadIconDark={
                  activeTabs[2].active_tab === 1
                    ? ChipNotificationRoundedLeadIconDarkHTML.default
                    : ChipNotificationRoundedLeadIconDarkJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="TrailIcon">Chip notifications with trailing icon</h3>

          <PreviewBlock
            id="TrailIcon"
            HtmlComponent={ChipNotificationRoundedTrailIconHTML.default}
            JsxComponent={ChipNotificationRoundedTrailIconJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <ChipNotificationAllRoundedTrailIconPreview
                ChipNotificationRoundedTrailIcon={
                  activeTabs[3].active_tab === 1
                    ? ChipNotificationRoundedTrailIconHTML.default
                    : ChipNotificationRoundedTrailIconJsx.default
                }
                ChipNotificationRoundedTrailIconElevated={
                  activeTabs[3].active_tab === 1
                    ? ChipNotificationRoundedTrailIconElevatedHTML.default
                    : ChipNotificationRoundedTrailIconElevatedJsx.default
                }
                ChipNotificationRoundedTrailIconDark={
                  activeTabs[3].active_tab === 1
                    ? ChipNotificationRoundedTrailIconDarkHTML.default
                    : ChipNotificationRoundedTrailIconDarkJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="RoundedFullLeadingButton">
            Fully rounded chip notifications with leading button
          </h3>

          <PreviewBlock
            id="RoundedFullLeadingButton"
            HtmlComponent={ChipNotificationRoundedFullLeadButtonHTML.default}
            JsxComponent={ChipNotificationRoundedFullLeadButtonJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <ChipNotificationAllRoundedFullLeadButtonPreview
                ChipNotificationRoundedFullLeadButton={
                  activeTabs[4].active_tab === 1
                    ? ChipNotificationRoundedFullLeadButtonHTML.default
                    : ChipNotificationRoundedFullLeadButtonJsx.default
                }
                ChipNotificationRoundedFullLeadButtonTrailLink={
                  activeTabs[4].active_tab === 1
                    ? ChipNotificationRoundedFullLeadButtonTrailLinkHTML.default
                    : ChipNotificationRoundedFullLeadButtonTrailLinkJsx.default
                }
                ChipNotificationRoundedFullLeadButtonElevated={
                  activeTabs[4].active_tab === 1
                    ? ChipNotificationRoundedFullLeadButtonElevatedHTML.default
                    : ChipNotificationRoundedFullLeadButtonElevatedJsx.default
                }
                ChipNotificationRoundedFullLeadButtonTrailLinkElevated={
                  activeTabs[4].active_tab === 1
                    ? ChipNotificationRoundedFullLeadButtonTrailLinkElevatedHTML.default
                    : ChipNotificationRoundedFullLeadButtonTrailLinkElevatedJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="RoundedFullTrailingButton">
            Fully rounded chip notifications with trailing button
          </h3>

          <PreviewBlock
            id="RoundedFullTrailingButton"
            HtmlComponent={ChipNotificationRoundedFullTrailButtonHTML.default}
            JsxComponent={ChipNotificationRoundedFullTrailButtonJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <ChipNotificationAllRoundedFullTrailButtonPreview
                ChipNotificationRoundedFullTrailButton={
                  activeTabs[5].active_tab === 1
                    ? ChipNotificationRoundedFullTrailButtonHTML.default
                    : ChipNotificationRoundedFullTrailButtonJsx.default
                }
                ChipNotificationRoundedFullTrailButtonLeadLink={
                  activeTabs[5].active_tab === 1
                    ? ChipNotificationRoundedFullTrailButtonLeadLinkHTML.default
                    : ChipNotificationRoundedFullTrailButtonLeadLinkJsx.default
                }
                ChipNotificationRoundedFullTrailButtonElevated={
                  activeTabs[5].active_tab === 1
                    ? ChipNotificationRoundedFullTrailButtonElevatedHTML.default
                    : ChipNotificationRoundedFullTrailButtonElevatedJsx.default
                }
                ChipNotificationRoundedFullTrailButtonLeadLinkElevated={
                  activeTabs[5].active_tab === 1
                    ? ChipNotificationRoundedFullTrailButtonLeadLinkElevatedHTML.default
                    : ChipNotificationRoundedFullTrailButtonLeadLinkElevatedJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="RoundedFullGroupedAvatars">
            Fully rounded chip notifications with grouped avatars
          </h3>

          <PreviewBlock
            id="RoundedFullGroupedAvatars"
            HtmlComponent={
              ChipNotificationRoundedFullGroupedAvatarsHTML.default
            }
            JsxComponent={ChipNotificationRoundedFullGroupedAvatarsJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <ChipNotificationRoundedFullAvatarsPreview
                ChipNotificationRoundedFullGroupedAvatars={
                  activeTabs[6].active_tab === 1
                    ? ChipNotificationRoundedFullGroupedAvatarsHTML.default
                    : ChipNotificationRoundedFullGroupedAvatarsJsx.default
                }
                ChipNotificationRoundedFullGroupedAvatarsElevated={
                  activeTabs[6].active_tab === 1
                    ? ChipNotificationRoundedFullGroupedAvatarsElevatedHTML.default
                    : ChipNotificationRoundedFullGroupedAvatarsElevatedJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="RoundedFullLeadIcon">
            Fully rounded chip notifications with leading icon
          </h3>

          <PreviewBlock
            id="RoundedFullLeadIcon"
            HtmlComponent={ChipNotificationRoundedFullLeadIconHTML.default}
            JsxComponent={ChipNotificationRoundedFullLeadIconJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <ChipNotificationAllRoundedFullLeadIconPreview
                ChipNotificationRoundedFullLeadIcon={
                  activeTabs[7].active_tab === 1
                    ? ChipNotificationRoundedFullLeadIconHTML.default
                    : ChipNotificationRoundedFullLeadIconJsx.default
                }
                ChipNotificationRoundedFullLeadIconElevated={
                  activeTabs[7].active_tab === 1
                    ? ChipNotificationRoundedFullLeadIconElevatedHTML.default
                    : ChipNotificationRoundedFullLeadIconElevatedJsx.default
                }
                ChipNotificationRoundedFullLeadIconDark={
                  activeTabs[7].active_tab === 1
                    ? ChipNotificationRoundedFullLeadIconDarkHTML.default
                    : ChipNotificationRoundedFullLeadIconDarkJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="RoundedFullTrailIcon">
            Fully rounded chip notifications with trailing icon
          </h3>

          <PreviewBlock
            id="RoundedFullTrailIcon"
            HtmlComponent={ChipNotificationRoundedFullTrailIconHTML.default}
            JsxComponent={ChipNotificationRoundedFullTrailIconJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <ChipNotificationAllRoundedFullTrailIconPreview
                ChipNotificationRoundedFullTrailIcon={
                  activeTabs[8].active_tab === 1
                    ? ChipNotificationRoundedFullTrailIconHTML.default
                    : ChipNotificationRoundedFullTrailIconJsx.default
                }
                ChipNotificationRoundedFullTrailIconElevated={
                  activeTabs[8].active_tab === 1
                    ? ChipNotificationRoundedFullTrailIconElevatedHTML.default
                    : ChipNotificationRoundedFullTrailIconElevatedJsx.default
                }
                ChipNotificationRoundedFullTrailIconDark={
                  activeTabs[8].active_tab === 1
                    ? ChipNotificationRoundedFullTrailIconDarkHTML.default
                    : ChipNotificationRoundedFullTrailIconDarkJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            Highlight meaningful information, or direct users to important
            content.
          </p>
        </Section>

        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>
            Chip notification component have an internal padding of 0.125rem
            (2px) and 1rem (16px) on the left or right, opposite of the included
            button. They can include the following elements:
          </p>
          <ul>
            <li>
              <strong>Text: </strong> 1rem (16px) font size.
            </li>
            <li>
              <strong>Action buttons: </strong> a small size button (see{" "}
              <Link to="/components/buttons">buttons</Link> ).
            </li>
          </ul>
        </Section>

        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <ul>
            <li>
              The Chip notification does not require any additional
              accessibility settings. For the included{" "}
              <code>&lt;button&gt;</code> check{" "}
              <Link to="/components/buttons">buttons</Link>.
            </li>
          </ul>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}
